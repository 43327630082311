// import logo from './logo.svg';
import './App.css';

import Navbar from './component/Navbar'
import React, { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './component/Home';
import About from './component/About';
import Service from './component/Service';
import Menu from './component/Menu';
import Contact from './component/Contact';



const App = () => {
  return (
    <BrowserRouter>
      <Navbar/>
        <Routes>
          <Route path='/' element={<Home/>}/> 
          <Route path='wedding-caterers-dahisar' element={<About/>} />
          <Route path='/best-caterers-in-dahisar' element={<Service/>} />
          <Route path='/party-caterers-dahisar' element={<Menu/>} />
          <Route path='/best-food-caterers-in-dahisar' element={<Contact/>} />
        </Routes>
      </BrowserRouter>
      
  )
}

export default App;



