import '../css/navbar.css'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo  from '../images/logo1.png';
import { HashLink } from "react-router-hash-link";
import $ from "jquery";
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import Accordion from 'react-bootstrap/Accordion';
import {NavLink,Link} from 'react-router-dom'
const navbar = () => {
   
    const navLinkStyle = ({ isActive }) => {
        return {
            color: isActive ? '#ff0101' : 'rgb(247, 194, 146)',
        }
    }
   
    return (
        <>
      {/* <nav class="navbar navbar-expand-lg">
                <div className="container">
                    <Link to="/"><a className="navbar-brand" href="logo"><img src={logo} alt="brand"/></a></Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse collapse" id="navbarNav">
                        <ul className="navbar-nav">
                            <li className="nav-item">
                              <NavLink style={navLinkStyle} href="/"><Link to="/">Home</Link></NavLink>
                            </li>
                            <li className="nav-item">
                               <NavLink style={navLinkStyle} href="/About"><Link to="/About">About</Link></NavLink>
                            </li>
                            <li className="nav-item">
                               <NavLink style={navLinkStyle} href="/Service"><Link to="/Service" >Services</Link></NavLink>
                            </li>
                            <li className="nav-item">
                            <NavLink style={navLinkStyle} href="/Menu"><Link to="/Menu" >Menu</Link></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink style={navLinkStyle} href="/Contact"><Link to="/Contact" >Contact</Link></NavLink>
                            </li>
                          
                        </ul>
                    </div>
                </div>
            </nav> */}


            <Navbar  collapseOnSelect expand="lg">
        <Container>
        <Navbar.Brand href="/" className="logo"><Link to="/" >
          <img className="logo-two" src={logo}  alt="logo"/>
          </Link> </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-start">
            <Nav className="me-auto">
              <Nav.Link className="" href="#"><Link to="/" className="nav-link">Home</Link>
                
              </Nav.Link>
              <Nav.Link href="wedding-caterers-dahisar"><Link to="wedding-caterers-dahisar" className="nav-link">About</Link></Nav.Link>
              <Nav.Link href="best-caterers-in-dahisar"><Link to="best-caterers-in-dahisar" className="nav-link">Services</Link></Nav.Link>
            
              <Nav.Link href="party-caterers-dahisar"><Link to="party-caterers-dahisar" className="nav-link">Menu</Link></Nav.Link>
              <Nav.Link href="best-food-caterers-in-dahisar" className=""><Link to="best-food-caterers-in-dahisar" className="nav-link"> Contact</Link>
               
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
        </>
    )
}

export default navbar;