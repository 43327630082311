
import "../css/About.css";
import "../css/Service.css";
import "../css/Menu.css";
import { MetaTags } from "react-meta-tags";
// Import Swiper styles
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Footer from "./Footer";
import food1 from "../images/gallery/17.jpg";
import food2 from "../images/gallery/4.jpg";
import food3 from "../images/gallery/41.jpg";

import pic from "../images/gallery/indian.jpg";

import Zoom from 'react-reveal/Zoom';

import React from 'react'

import MenuImage1 from "../images/Menu 1.jpg";
import MenuImage2 from "../images/Menu 2.jpeg";
import MenuImage3 from "../images/menu2.jpg";
const Menu = () => {
    return (
        <>

 {/* --------------------------------seo start--------------------------------- */}
 <MetaTags>
        <title>
          {" "}
          Party Caterers in Dahisar | The Express Caters
        </title>
        <meta name="google-site-verification" content="7SedB4wBTSXQ-5jGmNcsjt5cV7hPKrgS31utL8lPh3c" />
        <meta
          name="description"
          content="Discover the Party Caterers in Dahisar to elevate your events with delicious cuisines and impeccable service. Our caterers ensure unforgettable experiences ."
        />
        <meta
          name="keywords"
          content="Caterers in Dahisar,
          Wedding Caterers in Dahisar,
          Best Cateres In Dahisar,
          Party Cateres In Dahisar,
          Best food Cateres In Dahisar"
        />
        <meta
          property="og:title"
          content="Party Caterers in Dahisar | The Express Caters"
        />
        <meta
          property="og:description"
          content="Discover the Party Caterers in Dahisar to elevate your events with delicious cuisines and impeccable service. Our caterers ensure unforgettable experiences ."
        />
        <link
          rel="canonical"
          href="https://theexpresscaterers.in/party-caterers-dahisar"
        />

<meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Party Caterers in Dahisar | The Express Caters" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://theexpresscaterers.in/" />
        <meta property="og:description" 
        content="Discover the Party Caterers in Dahisar to elevate your events with delicious cuisines and impeccable service. Our caterers ensure unforgettable experiences ." />

      </MetaTags>
       {/* --------------------------------seo end--------------------------------- */}

            <section className="menutop  mt-2" id=" ">
                <div className="container ">
                    <div className="row d-flex justify-content-center ">
                        <div className="col-md-6"></div>

                        <div className="col-md-6">
                            <div className="innovDetail">

                                <h1>Party Caterers in Dahisar</h1>
                                <p> We are the party caterers in dahisar, So we understand well that the type of event will determine the type of services you need and as we are the party caterers in dahisar We provide you all what you need.
</p>
                                <p>
                                Get the best quote from “The Express Caterers” and book your date plan your wedding hassle free.

</p>
                                    <p>Call us on the no. 9820124036 or 9820143669 or book an appointment and visit us anytime of the day (Monday to Friday) from 11:00 am to 6:00 pm. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="p-lg-5">
                <div class="container">
                    <div class="titleSection ">

                        <h2>Welcome to the best catering company
                        </h2>

                    </div>
                    <div class="row d-flex justify-content-center align-items-center pt-lg-5 pt-2">
                        <div class="col-md-3 col-12">
                            <div class="menuimg">  <img src={pic} alt="indian"/></div>
                            <div class="menuType">
                                <h2>Indian</h2>

                            </div>
                        </div>
                        <div class="col-md-3 col-12">
                            <div class="menuimg">  <img src={food1} alt="oriental"/></div>
                            <div class="menuType">
                                <h2>Oriental</h2>
                            </div>
                        </div>
                        <div class="col-md-3 col-12">
                            <div class="menuimg">  <img src={food2} alt="tandoor"/></div>
                            <div class="menuType">
                                <h2>Tandoor</h2>
                            </div>
                        </div>
                        <div class="col-md-3 col-12">
                            <div class="menuimg"> <img src={food3} alt="continental"/></div>
                            <div class="menuType">
                                <h2>Continental</h2>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* /////////////////////////////// section 2 /////////////////////////////////// */}

            <section className='FacilitiesSection2'>
                <div className='FacilitiesSection2-heading'>
                    {/* <Slide left>
                        <span><img src={Facilitieslogo01} alt="cat"/></span>
                    </Slide> */}

                    <Zoom>
                        <h2>Our Menu cards</h2>
                    </Zoom>

                    {/* <Slide right>
                        <span><img src={Facilitieslogo02} alt="dog"/></span>
                    </Slide> */}
                </div>
                <div className='Container'>
                    <div className='Facilities-Section2-tabpanel'>

                        <Tabs
                            defaultActiveKey="Treatment"
                            id="justify-tab-example"
                            className="mb-3"
                            justify

                        >
                            <Tab eventKey="Treatment" title="Menu Front">
                                <div className="conatiner">
                                    <div className="Menu-Image-Con">
                                        <div className="Menu-Image">
                                            <img src={MenuImage1} alt="Menu1" />
                                        </div>
                                    </div>
                                </div>
                            </Tab> <Tab eventKey="Vaccination" title="Menu Back">
                                <div className="conatiner">
                                    <div className="Menu-Image-Con">
                                        <div className="Menu-Image">
                                            <img src={MenuImage2} alt="Menu3" />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Menucard" title="Menu 2">
                                <div className="conatiner">
                                    <div className="Menu-Image-Con">
                                        <div className="Menu-Image">
                                            <img src={MenuImage3} alt="Menu2" />
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                           

                        </Tabs>

                    </div>
                </div>
            </section>
            {/* /////////////////// */}

            <Footer />
        </>
    )
}




export default Menu
