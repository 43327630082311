import React from "react";
import "../css/About.css";
import { MetaTags } from "react-meta-tags";
import { Swiper, SwiperSlide } from "swiper/react";
import brazil from '../images/express/brazil-col.jpg';
import prakash from '../images/express/Prakash.jpg';
import martin from '../images/express/grasmina-martin.jpg';
import patrik from '../images/express/Patrik-corda.jpg';

import "swiper/css";
import { Pagination } from "swiper";
import { Navigation } from "swiper";
import Footer from "./Footer";
// import "../css/Footer.css";
import aboutImg from "../images/express/cake1.jpg";

import aboutpic from "../images/download (1).jpg";

const about = () => {
  return (
    <>
     {/* --------------------------------seo start--------------------------------- */}
 
     <MetaTags>
        <title>
          {" "}
          Wedding Caterers in Dahisar | The Express Caters
        </title>
        <meta name="google-site-verification" content="7SedB4wBTSXQ-5jGmNcsjt5cV7hPKrgS31utL8lPh3c" />
        <meta
          name="description"
          content="Indulge in the finest Wedding Caterers in Dahisar. The Express Caters culinary experts craft exquisite dishes to elevate your special day."
        />
        <meta
          name="keywords"
          content="Caterers in Dahisar,
          Wedding Caterers in Dahisar,
          Best Cateres In Dahisar,
          Party Cateres In Dahisar,
          Best food Cateres In Dahisar"
        />
        <meta
          property="og:title"
          content=" Wedding Caterers in Dahisar | The Express Caters"
        />
        <meta
          property="og:description"
          content="Indulge in the finest Wedding Caterers in Dahisar. The Express Caters culinary experts craft exquisite dishes to elevate your special day."
        />
        <link
          rel="canonical"
          href="https://theexpresscaterers.in/wedding-caterers-dahisar"
        />

<meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Wedding Caterers in Dahisar | The Express Caters" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://theexpresscaterers.in/" />
        <meta property="og:description" 
        content="Indulge in the finest Wedding Caterers in Dahisar. The Express Caters culinary experts craft exquisite dishes to elevate your special day." />

      </MetaTags>
 
       {/* --------------------------------seo end--------------------------------- */}
      <section className="abouttop  mt-2" id=" ">
        <div className="container ">
          <div className="row d-flex justify-content-center ">
            <div className="col-md-6"></div>

            <div className="col-md-6">
              <div className="innovDetail">
                <h1> Wedding Caterers in Dahisar</h1>
                <p>“The EXPRESS CATERERS” offers a wide variety of catering services in Mumbai with amazing food to fit both your event and your budget. And we are known as best wedding caterers in dahisar. Our full catering services handle all aspects of events such as preparation, creation, delivery, and presentation of food. We are a team of professional caterers serving the catering needs of weddings, corporate, outdoor, small parties, and more. We understand well that the type of event will determine the type of services you need. Our catering solutions are tailored and designed to meet what you are looking for and what to create to inspire an exciting catering experience.

             </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section id="" className="ourStory">
        <div className="container">
          <div className="titleSection">
            <span>About us</span>
            <h2>our story of success</h2>
            {/* <p>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged.
            </p> */}
          </div>
          <div className="row p-lg-5">
            <div className="col-md-7 col-12 m-0 p-0">
              <div className="about-Detail">
                <h2>
                  A Perfect Blend of Sensational Food, Inspiring Ideas and
                  Professional Staff
                </h2>
                {/* <h2>just one call away</h2> */}
                <p>
                “The Express Caterers” is very committed in maintaining the highest level of professionalism through integrity, public relations; interpersonal skills, and a comprehensive bouquet of food & beverages, complemented with best service practices in the service industry.
   </p>
                <p>
                Our specialized external trainer accomplishes the training task for our employees from time to time.
 </p>
              </div>
            </div>
            <div className="col-md-5 col-12 m-0 p-0">
              <div className="about-image">
                <img src={aboutImg} className="customized cakes" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="best p-lg-5">
        <div class="container">
          <div className="titleSection">
            <span>Things</span>
            <h2>Makes us best</h2>
         <p>Here are the top reasons for making “The Express Caterers” of choice for your next big celebration:
</p>
          </div>
          <div class="row d-flex justify-content-center align-items-center pt-lg-5 pt-2">
            <div class="col-md-6 col-12">
          
              <div class="flist">
             
                <ul>
                  <li>
                    <i class=""></i>
                    <span>Credibility:</span>
                    <p>
                    Our credibility stems from years of experience that has exposed us to customers of varied cultures, cuisines & ideas.
  </p>
                  </li>
                  <li>
                    <i class=""></i>
                    <span>Varied Cuisines: </span>
                    <p>
                    Although we specialize in Indian & Costal dishes viz Goan, Mangalorean, Keralite we cater equally well to multi cuisines such as Continental, Chinese and Oriental.
 </p>
                  </li>
                  <li>
                    <i class=""></i>
                    <span>
                    Infrastructure:
                    </span>
                    <p>
                    Our experience is well complimented with adequate infrastructure. We boast of a spacious and well equipped central kitchen, ware house and office that has all the required certification from Government Authorities.
     </p>
                  </li>
                 
                </ul>
              </div>
            </div>
            <div class="col-md-6 col-12">
           
              <div class="flist">
             
                <ul>
                  <li>
                <span>Immaculate Cuisine Innovation:</span>
                  <p>
                  We take the liberty of experimenting and innovating the cuisines that we serve, so that your guests always have something new to look forward to! Fulfill your comfort food cravings.
 </p>
                  </li>
                <li>
                    <i class=""></i>
                    <span>
                    Staff:
                    </span>
                    <p>
                    Trained kitchen staff with many years of experience in cooking a variety of dishes from varied Cuisines. Kitchen and Office staff are periodically provided with training programmes to upgrade their knowledge and Skills.
   </p>
                  </li>
                  <li>
                    <i class=""></i>
                    <span>
                    Attitude Towards Business: 
                    </span>
                    <p>
                    At Express Caterers growth doesn’t necessarily mean size and quantity but to achieve perfection in the area of Food preparation and service. Every product is subject to development to make what is already good, better.   </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="testimonial">
        <div class="container">
          <Swiper
            pagination={{
              type: "progressbar",
            }}
            navigation={true}
            modules={[Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div class="testimonial4_slide">
               
                <p>
                I always choose The Express Caterers for my party food orders; the food quality is mind blowing regarding the portion for the food it is value for money, keep it up with the same food quality and service     </p>
                <h4>  Mrs. Renita  D’Souza</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">

                <p>
                My Son’s wedding catering was managed by The Express Caterers we were around 450 guests, to my surprise all my guests and my daughter in laws family members really enjoyed the food and gave some excellent comments about the food, it was indeed a memorable occasion for all of us.             </p>
                <h4>Mr. Walter Fernandes</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                
                <p>
                My Daughters First Holy Communion party order was given to The Express Caterers and the food was excellent all my guests really enjoyed it.
                </p>
                <h4>Mrs. Glenda Dias</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                
                <p>
                My Daughters first birthday party was a memorable event for all of us, it was for the first time we had placed an order with the express caterers it didn’t just turned out to be very good but I would say it was excellent the food taste and quality was great.
                </p>
                <h4>Mr. Anthony D’mello </h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                {/* <img
                  src="https://i.ibb.co/8x9xK4H/team.jpg"
                  class="img-circle img-responsive"
                /> */}
                <p>
                The Express caterers have almost done 3 wedding functions in my family we really like the food and the service that they provide also the rates are affordable I see value for money. 
                </p>
                <h4> Mrs.Cynthia Carvalho</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                
                <p>
                My Sons Ordination (Fr Rodolph Dsouza sdb) party was taken care by “The Express Caterers” everyone enjoyed the food specially the Priests who had come from far, keep it up express caterers. 
                </p>
                <h4>Mr. Raymond D’Souza</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                
                <p>
                I stay in Sewri but when it comes to order food for any of our family functions I always call the express caterers and they delivery it for me.
                </p>
                <h4>Mr. Deepak Seth</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                
                <p>
                My kids love the fish fingers from express caterers and it has always been in our list when we order food from them.
                </p>
                <h4>Ms. Kalpana Gorde</h4>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div class="testimonial4_slide">
                
                <p>
                For my wedding of around 1000+ people we had many guests who came from Pune from my wife’s side and believe me they were all praising about the taste and quality of the food I was a bit worried if they would really love the food from Mumbai, but express caterers took care of it good job.
                </p>
                <h4>Mr Roy Salves  </h4>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </section>


     
  {/* End */}
  <section>
  <div className="container">
  <div className="titleSection">
            {/* <span>Things</span> */}
            <h2>our Team members</h2>
            {/* <p>
              Lorem Ipsum has been the industry's standard dummy text ever since
              the 1500s, when an unknown printer took a galley of type and
              scrambled it to make a type specimen book. It has survived not
              only five centuries, but also the leap into electronic
              typesetting, remaining essentially unchanged.
            </p> */}
          </div>
    <div className="row text-center pt-lg-5 pt-2">
      {/* Team item */}
      <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-light rounded shadow-sm py-5 px-4">
          <img
            src={brazil}
            alt="Mr. Brazil Colaco"
            width={100}
            className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
          />
          <h5 className="mb-0">Mr. Brazil Colaco</h5>
          <span className="small text-uppercase text-muted">9820124036</span>
          {/* <ul className="social mb-0 list-inline mt-3">
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-facebook-f" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-linkedin" />
              </a>
            </li>
          </ul> */}
        </div>
      </div>
      {/* End */}
    
      {/* Team item */}
      <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-light rounded shadow-sm py-5 px-4">
          <img
            src={prakash}
            alt="Prakash Nagpal"
            width={100}
            className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
          />
          <h5 className="mb-0">Prakash Nagpal</h5>
          <span className="small text-uppercase text-muted"> 9821723302</span>
          
        </div>
      </div>
      {/* End */}
      {/* Team item */}
      <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-light rounded shadow-sm py-5 px-4">
          <img
            src={martin}
            alt="Mrs. Grasmina Martin"
            width={100}
            className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
          />
          <h5 className="mb-0">Mrs. Grasmina Martin</h5>
          <span className="small text-uppercase text-muted"> 8850056326</span>
          {/* <ul className="social mb-0 list-inline mt-3">
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-facebook-f" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-linkedin" />
              </a>
            </li>
          </ul> */}
        </div>
      </div>
      {/* End */}
        {/* Team item */}
        <div className="col-xl-3 col-sm-6 mb-5">
        <div className="bg-light rounded shadow-sm py-5 px-4">
          <img
            src={patrik}
            alt="Patrick Corda"
            width={100}
            className="img-fluid rounded-circle mb-3 img-thumbnail shadow-sm"
          />
          <h5 className="mb-0">Patrick Corda</h5>
          <span className="small text-uppercase text-muted">9920216126 </span>
          {/* <ul className="social mb-0 list-inline mt-3">
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-facebook-f" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-twitter" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-instagram" />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" className="social-link">
                <i className="fa fa-linkedin" />
              </a>
            </li>
          </ul> */}
        </div>
      </div>
      {/* End */}
    </div>
  </div>
  </section>
 


 
      <Footer />
    </>
  );
};

export default about;
